const testMinLength = (value) => {
  let isValid = null;
  let iconName;
  let iconColor = "fill-white/50";
  let textColor = "fill-white/50";
  if (!value) {
    iconName = "IconDot";
    iconColor = "fill-white";
  } else {
    isValid = value.length >= 8;
    iconName = isValid ? "IconCheck" : "IconTimes";
    iconColor = isValid ? "fill-white/50" : "fill-red-800";
    textColor = isValid ? "text-white opacity-50" : "text-red-800";
  }

  return {
    iconName,
    iconColor,
    isValid,
    textColor,
  };
};

const testUppercase = (value) => {
  const regex = /[A-Z]/;
  let isValid = null;
  let iconName;
  let iconColor = "fill-white/50";
  let textColor = "fill-white/50";

  if (!value) {
    iconName = "IconDot";
    iconColor = "fill-white";
  } else {
    isValid = regex.test(value);
    iconName = isValid ? "IconCheck" : "IconTimes";
    iconColor = isValid ? "fill-white/50" : "fill-red-800";
    textColor = isValid ? "text-white opacity-50" : "text-red-800";
  }

  return {
    iconName,
    iconColor,
    isValid,
    textColor,
  };
};

const testLowercase = (value) => {
  const regex = /[a-z]/;
  let isValid = null;
  let iconName;
  let iconColor = "fill-white/50";
  let textColor = "fill-white/50";

  if (!value) {
    iconName = "IconDot";
    iconColor = "fill-white";
  } else {
    isValid = regex.test(value);
    iconName = isValid ? "IconCheck" : "IconTimes";
    iconColor = isValid ? "fill-white/50" : "fill-red-800";
    textColor = isValid ? "text-white opacity-50" : "text-red-800";
  }

  return {
    iconName,
    iconColor,
    isValid,
    textColor,
  };
};

const testHasNumber = (value) => {
  const regex = /[0-9]/;
  let isValid = null;
  let iconName;
  let iconColor = "fill-white/50";
  let textColor = "fill-white/50";

  if (!value) {
    iconName = "IconDot";
    iconColor = "fill-white";
  } else {
    isValid = regex.test(value);
    iconName = isValid ? "IconCheck" : "IconTimes";
    iconColor = isValid ? "fill-white/50" : "fill-red-800";
    textColor = isValid ? "text-white opacity-50" : "text-red-800";
  }

  return {
    isValid,
    iconName,
    iconColor,
    textColor,
  };
};

const testHasSymbol = (value) => {
  const regex = /[^A-Za-z0-9\s]/;
  let isValid = null;
  let iconName;
  let iconColor = "fill-white/50";
  let textColor = "fill-white/50";

  if (!value) {
    iconName = "IconDot";
    iconColor = "fill-white";
  } else {
    isValid = regex.test(value);
    iconName = isValid ? "IconCheck" : "IconTimes";
    iconColor = isValid ? "fill-white/50" : "fill-red-800";
    textColor = isValid ? "text-white opacity-50" : "text-red-800";
  }

  return {
    iconName,
    iconColor,
    isValid,
    textColor,
  };
};

export default function () {
  return {
    testMinLength,
    testUppercase,
    testLowercase,
    testHasNumber,
    testHasSymbol,
  };
}
